import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { navigate, FormattedMessage, FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl";

import * as constants from "../../app.constant";
import AppLayout from "../../components/shared/AppLayout";
import InputField from "../../components/form/input-field";
import deviceFrontImg from "../../assets/images/upload-example-device-front.jpg";
import deviceBackImg from "../../assets/images/upload-example-device-back.jpg";
import deviceIMEISerialNoImg from "../../assets/images/upload-example-device-imei-serial.jpg";
import devicePurchaseReceiptImg from "../../assets/images/upload-example-device-purchase-receipt.jpg";
import deviceTestScreenshotOneImg from "../../assets/images/upload-example-device-test-screenshoot-one.jpg";
import deviceTestScreenshotTwoImg from "../../assets/images/upload-example-device-test-screenshoot-two.jpg";
import successIcon from "../../assets/images/valid.svg";

const inputs = [
    { label: "reuploadDevicePhoto.form.fields.0.label", name: "deviceFront", note: "reuploadDevicePhoto.form.fields.0.note", exampleImageSrc: deviceFrontImg },
    { label: "reuploadDevicePhoto.form.fields.1.label", name: "deviceBack", note: "reuploadDevicePhoto.form.fields.1.note", exampleImageSrc: deviceBackImg },
    { label: "reuploadDevicePhoto.form.fields.2.label", name: "deviceIMEISerialNo", note: "reuploadDevicePhoto.form.fields.2.note", exampleImageSrc: deviceIMEISerialNoImg },
    { label: "reuploadDevicePhoto.form.fields.3.label", name: "devicePurchaseReceipt", note: "reuploadDevicePhoto.form.fields.3.note", exampleImageSrc: devicePurchaseReceiptImg },
    { label: "reuploadDevicePhoto.form.fields.4.label", name: "deviceTestScreenshotOne", note: "reuploadDevicePhoto.form.fields.4.note", exampleImageSrc: deviceTestScreenshotOneImg },
    { label: "reuploadDevicePhoto.form.fields.5.label", name: "deviceTestScreenshotTwo", note: "reuploadDevicePhoto.form.fields.5.note", exampleImageSrc: deviceTestScreenshotTwoImg },
];

const initialValues = {
    deviceFront: null,
    deviceBack: null,
    deviceIMEISerialNo: null,
    devicePurchaseReceipt: null,
    deviceTestScreenshotOne: null,
    deviceTestScreenshotTwo: null,
    deviceOrderNumber: "",
};

const fileTypes = [ "image/jpg", "image/jpeg", "image/png" ];
const fileLimit = 20000000;
const fileErrors = [
    { type: "fileSize", error: "File Size is too large" },
    { type: "fileType", error: "Unsupported File Format" }
]

const PageReuploadDevicePhoto = ( props ) => {
    const intl = useIntl();
    const messages = intl.messages;
    const [ selectedOption, setSelectedOption ] = useState( inputs[0] );

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            deviceFront: Yup.mixed()
                .test( fileErrors[0].type, fileErrors[0].error, value => value?.size <= fileLimit )
                .test( fileErrors[1].type, fileErrors[1].error, value => fileTypes.includes( value?.type )),
            deviceBack: Yup.mixed()
                .test( fileErrors[0].type, fileErrors[0].error, value => value?.size <= fileLimit )
                .test( fileErrors[1].type, fileErrors[1].error, value => fileTypes.includes( value?.type )),
            deviceIMEISerialNo: Yup.mixed()
                .test( fileErrors[0].type, fileErrors[0].error, value => value?.size <= fileLimit )
                .test( fileErrors[1].type, fileErrors[1].error, value => fileTypes.includes( value?.type )),
            devicePurchaseReceipt: Yup.mixed()
                .test( fileErrors[0].type, fileErrors[0].error, value => value?.size <= fileLimit )
                .test( fileErrors[1].type, fileErrors[1].error, value => fileTypes.includes( value?.type )),
            deviceTestScreenshotOne: Yup.mixed()
                .test( fileErrors[0].type, fileErrors[0].error, value => value?.size <= fileLimit )
                .test( fileErrors[1].type, fileErrors[1].error, value => fileTypes.includes( value?.type )),
            deviceTestScreenshotTwo: Yup.mixed()
                .test( fileErrors[0].type, fileErrors[0].error, value => value?.size <= fileLimit )
                .test( fileErrors[1].type, fileErrors[1].error, value => fileTypes.includes( value?.type )),
            deviceOrderNumber: Yup.string().required("Please enter a order number"),
        }),
        onSubmit: ( values, action ) => {
            console.log(values);
            navigate(constants.ROUTES.ACTIVATION_SUCCESS);
        },
    });

    const onHandleFileUpload = ( event ) => {
        const name = event.currentTarget.name;
        const file = event.currentTarget.files[0];

        formik.setFieldValue( name, file );

        event.currentTarget.value = "";
    }

    const onHandleSelectOption = ( index ) => {
        setSelectedOption( inputs[index]  );
    }

    return (

        <AppLayout hasHeader={ true } hasHeaderLogo={ true } isBackBtn={ true } hasFooter={ true }>

            <div className="app-page page-reupload-device-photo">

                <div className="reupload-device-photo">

                    <div className="container">

                        <form className="reupload-device-photo__form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>

                            {
                                formik.values[selectedOption.name] && formik.errors[selectedOption.name] ? <div className="form-group__error invalid-feedback">{ formik.errors[selectedOption.name] }</div> : null
                            }

                            <label className="reupload-device-photo__input">
                                <span><FormattedMessage id="reuploadDevicePhoto.form.cta" /></span>
                                <input hidden type="file" name={ selectedOption.name } onChange={ onHandleFileUpload } />
                            </label>

                            <p>
                                <FormattedMessage id="reuploadDevicePhoto.headline" />
                            </p>

                            <div className="reupload-device-photo__device-select form-row">
                                {
                                    inputs.map(( input, index ) => {
                                        const inputName = input.name;
                                        const key = `${ inputName }-${ index }`;
                                        const active = inputName === selectedOption.name ? "reupload-device-photo__upload-button--is-active" : "";
                                        const isUploaded = formik.values[inputName];
                                        const uploadedClassName = isUploaded ? "reupload-device-photo__upload-button--is-uploaded text-bold" : "";

                                        return (
                                            <div className="col-6 col-md-4" key={ key }>
                                                <button className={`reupload-device-photo__upload-button ${ active } ${ uploadedClassName }`} type="button" onClick={() => onHandleSelectOption( index )}>
                                                    <FormattedMessage id={input.label} />
                                                    {isUploaded ? (
                                                        <img className="reupload-device-photo__upload-button-uploaded-icon img-fluid" src={successIcon} alt="" width="18" />
                                                    ) : null}
                                                </button>
                                            </div>
                                        );
                                    })
                                }
                            </div>

                            <p>
                                <FormattedHTMLMessage id={selectedOption.note} />
                            </p>

                            <div className="reupload-device-photo__device-photo-preview">
                                <img className="img-fluid" alt="" src={ formik.values[selectedOption.name] ? URL.createObjectURL( formik.values[selectedOption.name] ) : selectedOption.exampleImageSrc } />
                            </div>

                            {formik.values[selectedOption.name] ? (
                                <p className="reupload-device-photo__device-photo-filename">
                                    {formik.values[selectedOption.name]?.name}
                                </p>
                            ) : null}

                            <div className="reupload-device-photo__order-number">
                                <InputField
                                    label={messages["reuploadDevicePhoto.form.orderNumber.label"]}
                                    placeholder={messages["reuploadDevicePhoto.form.orderNumber.placeholder"]}
                                    name="deviceOrderNumber"
                                    formik={formik}
                                />
                            </div>

                            <p className="btn-container btn-container--center">
                                <button className="btn btn-lg btn-primary" type="submit" disabled={ !formik.isValid || !formik.dirty } onClick={ formik.handleSubmit }>
                                    <FormattedMessage id="reuploadDevicePhoto.submitCta" />
                                </button>
                            </p>

                        </form>

                    </div>

                </div>

            </div>

        </AppLayout>

    );
};

export default PageReuploadDevicePhoto;
